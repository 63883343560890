import React from 'react';
import {
  FcHome,
  FcGraduationCap,
  FcAbout,
  FcBriefcase,
  FcBusinessContact,
  FcNews,
} from 'react-icons/fc';
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby';
import useSound from 'use-sound';
import click from '../../static/sound/click.mp3'
import navOpen from '../../static/sound/nav-open.mp3'
import navClose from '../../static/sound/nav-close.mp3'
import vibrate from '../util/vibrate';

const Navigation = () => {
  const [openBurgerClassName, setOpenBurgerClassName] = React.useState('');
  const [play] = useSound(click, { volume: 0.5 })
  const [playNavOpen] = useSound(navOpen)
  const [playNavClose] = useSound(navClose)

  const handleMenuItemsClick = () => {
    play()
    vibrate(250)
  }

  const toggleOpenHamBurgerMenu = () => {
    if (!openBurgerClassName) {
      playNavOpen();
      setOpenBurgerClassName('is-active');
    } else {
      playNavClose();
      setOpenBurgerClassName('');
    }
  };

  return (
    <nav
      className="navbar is-fixed-bottom-touch has-shadow is-fixed-top-desktop"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <StaticImage src="../images/icon.png"
            width={28}
            height={28}
            quality={80}
            placeholder="tracedSVG"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Shishir Singh Logo" />
          <span className="ml-2 is-size-5 has-text-danger">Shishir Singh</span>
        </a>
        <button
          className={`navbar-burger ${openBurgerClassName}`}
          aria-label="menu"
          aria-expanded="false"
          onClick={toggleOpenHamBurgerMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div
        id="navbarBasicExample"
        className={`navbar-menu ${openBurgerClassName}`}
      >
        <div className="navbar-end">
          <div className="navbar-item">
            <Link activeClassName="has-text-warning-dark" to="/" onClick={handleMenuItemsClick}>
              <div className="level">
                <div className="level-item">
                  <FcHome size="2em" />
                  <span className="nav-desc mx-2">Home</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="navbar-item">
            <Link activeClassName="has-text-warning-dark" to="/about" onClick={handleMenuItemsClick}>
              <div className="level">
                <div className="level-item">
                  <FcAbout size="2em" />
                  <span className="nav-desc mx-2">About</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="navbar-item">
            <Link activeClassName="has-text-warning-dark" to="/blog" onClick={handleMenuItemsClick}>
              <div className="level">
                <div className="level-item">
                  <FcNews size="2em" />
                  <span className="nav-desc mx-2">Blog</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="navbar-item">
            <Link activeClassName="has-text-warning-dark" to="/resume" onClick={handleMenuItemsClick}>
              <div className="level">
                <div className="level-item">
                  <FcGraduationCap size="2em" />
                  <span className="nav-desc mx-2">Resume</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="navbar-item">
            <Link activeClassName="has-text-warning-dark" to="/portfolio" onClick={handleMenuItemsClick}>
              <div className="level">
                <div className="level-item">
                  <FcBriefcase size="2em" />
                  <span className="nav-desc mx-2">Portfolio</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="navbar-item">
            <Link activeClassName="has-text-warning-dark" to="/contact" onClick={handleMenuItemsClick}>
              <div className="level">
                <div className="level-item">
                  <FcBusinessContact size="2em" />
                  <span className="nav-desc mx-2">Contact</span>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
