import React from "react"
import Navigation from "./NavigationBar"

import "../styles/index.scss"

export default function Layout({ children }) {
  return (
    <>
      <div>{children}</div>
      <Navigation />
    </>
  )
}
